import { FC } from "react"
import { Message } from "../models/Message"
import Markdown from "react-markdown"
import { Utils } from "../../shared/Utils"
import NextStepButton from "./NextStepButton"

type Props = {
  message: Message
  onSelectSendMessageButton: (text: string) => void
}
const ChatSystemMessage: FC<Props> = ({
  message,
  onSelectSendMessageButton,
}) => {
  function getStepsValues(obj): string[] {
    if (obj.nextSteps && typeof obj.nextSteps === "object") {
      const steps = Object.values(obj.nextSteps)
      return steps.filter((step) => typeof step === "string") as string[]
    } else {
      return []
    }
  }

  let steps: string[] = []
  try {
    steps = getStepsValues(
      Utils.extractJsonFromMarkdown(message.text).jsonObject,
    )
  } catch (e) {
    console.log(e)
  }

  return (
    <div key={message.id} className="">
      <div className="my-8">
        <div className="flex items-center">
          <span className="font-bold">Noah</span>
          <span className="ml-auto text-xs">
            {message.createdAt.toLocaleDateString()}
          </span>
        </div>
        <div className="flex mt-2">
          <div className="flex-1 w-0">
            <div className="flex-col">
              <Markdown
                className="mr-4 break-words markdown"
                children={message.text}
              >
                {}
              </Markdown>

              {steps.map((step) => (
                <div className="flex my-3">
                  <NextStepButton
                    text={step}
                    onClick={onSelectSendMessageButton}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="h-0.5 bg-divider my-6 rounded"></div>
    </div>
  )
}

export default ChatSystemMessage
