import { FC } from "react"

type Props = {
  className?: string
  text: string
}
const SidePanelHeaderItem: FC<Props> = ({ className, text }) => {
  return (
    <div className="w-52 rounded-md">
      <p className="p-4 text-xs text-text-light">{text}</p>
      <div className="h-0.5 bg-divider"></div>
    </div>
  )
}

export default SidePanelHeaderItem
