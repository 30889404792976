import React, { FC } from "react"
import { Toast } from "../shared/components/toasts/Toast"
import { useSignUp } from "./hooks/useSignUp"
import { BASE_URL } from "../shared/constants/constants"
import SignUpApiProvider from "./provider/SignUpApiProvider"

const SignUpPage: FC = () => {
  const { formik, loading } = useSignUp(new SignUpApiProvider(BASE_URL))

  return (
    <>
      <div className="flex h-screen">
        <div className="m-auto md:w-96 w-full pl-4 pr-4 ">
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full self-center px-6 py-8 rounded shadow-2xl">
              <div className="mt-4">
                <label className="form-control w-full max-w-xs">
                  <input
                    type={"text"}
                    placeholder="Email"
                    className="input input-bordered w-full max-w-xs"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit()
                      }
                    }}
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="label">
                      <p className="label-text-alt text-error">
                        {formik.errors.email}
                      </p>
                    </div>
                  ) : null}
                </label>
              </div>
              <div className="mt-4">
                <label className="form-control w-full max-w-xs">
                  <div className="flex">
                    <input
                      type={"text"}
                      placeholder="Password"
                      className="input input-bordered w-full max-w-xs mr-2"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          formik.handleSubmit()
                        }
                      }}
                      {...formik.getFieldProps("password")}
                    />
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="label">
                      <p className="label-text-alt text-error">
                        {formik.errors.password}
                      </p>
                    </div>
                  ) : null}
                </label>
              </div>
              <div className="mt-4">
                <label className="form-control w-full max-w-xs">
                  <div className="flex">
                    <input
                      type={"text"}
                      placeholder="Name"
                      className="input input-bordered w-full max-w-xs mr-2"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          formik.handleSubmit()
                        }
                      }}
                      {...formik.getFieldProps("name")}
                    />
                  </div>
                  {formik.touched.name && formik.errors.name ? (
                    <div className="label">
                      <p className="label-text-alt text-error">
                        {formik.errors.name}
                      </p>
                    </div>
                  ) : null}
                </label>
              </div>
              <div className="mt-4">
                <label className="form-control w-full max-w-xs">
                  <div className="flex">
                    <input
                      type={"text"}
                      placeholder="Role"
                      className="input input-bordered w-full max-w-xs mr-2"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          formik.handleSubmit()
                        }
                      }}
                      {...formik.getFieldProps("role")}
                    />
                  </div>
                  {formik.touched.role && formik.errors.role ? (
                    <div className="label">
                      <p className="label-text-alt text-error">
                        {formik.errors.role}
                      </p>
                    </div>
                  ) : null}
                </label>
              </div>

              <button
                className="btn h-10 btn-primary  mt-8 w-full !py-0"
                type="submit"
              >
                {loading ? (
                  <span className="loading loading-spinner text-white"></span>
                ) : (
                  ""
                )}
                <p className="text-white font-bold ">Signup</p>
              </button>
            </div>
          </form>
        </div>

        <Toast />
      </div>
    </>
  )
}

export default SignUpPage
