import React, { FC } from "react"

import "react-toastify/dist/ReactToastify.css"
import {
  Route,
  Routes,
  createBrowserRouter,
  RouterProvider,
  NavigationType,
} from "react-router-dom"
import LoginPage from "../../../login/LoginPage"
import PrivateRoute from "./PrivateRoute"
import {
  CHAT_LIST_ROUTE,
  CHAT_ROUTE,
  LOGIN_ROUTE,
  SIGN_UP_ROUTE,
  LOGOUT_ROUTE,
} from "../../constants/route.constants"
import { ChatPage } from "../../../chat/pages/ChatPage"
import { ChatsProvider } from "../../../chat/hooks/ChatsContext"
import { AuthProvider } from "../../../auth/context/AuthContext"
import SignUpPage from "../../../signUp/SignUpPage"
import { ChatListPage } from "../../../chat/pages/ChatListPage"
import LogoutPage from "../../../auth/logout/LogoutPage"

const Router: FC = () => {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  )
}

const router = createBrowserRouter([
  {
    path: LOGIN_ROUTE,
    element: <LoginPage />,
  },
  {
    path: CHAT_ROUTE,
    element: (
      <ChatsProvider>
        <PrivateRoute component={<ChatPage />} />
      </ChatsProvider>
    ),
  },
  {
    path: SIGN_UP_ROUTE,
    element: <PrivateRoute component={<SignUpPage />} />,
  },
  {
    path: LOGOUT_ROUTE,
    element: <LogoutPage />,
  },
  {
    path: CHAT_LIST_ROUTE,
    element: (
      <ChatsProvider>
        <PrivateRoute component={<ChatListPage />} />
      </ChatsProvider>
    ),
  },
  // Añade más rutas según sea necesario
])
export default Router
