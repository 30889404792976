import ApiProvider from "../../shared/providers/ApiProvider"
import AuthProvider from "./AuthProvider"
import BadRequestError from "../../shared/exceptions/BadRequestError"
import APIResponseData from "../../shared/models/APIResponseData"
import GenericWithTextError from "../../shared/exceptions/GenericWithTextError"

export default class SignUpApiProvider
  extends ApiProvider
  implements AuthProvider
{
  async signUp(
    token: string,
    userId: string,
    email: string,
    password: string,
    name: string,
    role: string,
  ): Promise<void> {
    return fetch(`${this.baseUrl}api/v1/sign-up`, {
      method: "POST",
      headers: this.getHeaders(token),
      body: JSON.stringify({
        user_id: userId,
        email: email,
        password: password,
        name: name,
        role: role,
      }),
    }).then(async (response) => {
      await this.checkSignUpErrors(response)
    })
  }

  private async checkSignUpErrors(response: Response) {
    if (response.status >= 400) {
      const responseRaw = await response.json()
      console.log("response", response)
      if (responseRaw["error"]) {
        throw new GenericWithTextError(responseRaw["data"]["message"])
      }
    }
  }
}
