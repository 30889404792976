import React, { FC, useEffect, useState } from "react"
import ChatList from "../components/ChatList"
import { useSignOut } from "react-firebase-hooks/auth"
import { getAuth } from "firebase/auth"
import firebaseApp from "../../firebaseConfig"
import { notifySuccess } from "../../shared/components/toasts/ToastUtils"
import { useNavigate } from "react-router-dom"

export const ChatListPage: FC = () => {
  const history = useNavigate()
  const [signOut, loadingSignOut, error] = useSignOut(getAuth(firebaseApp))
  return (
    <div id="baseScroll" className="flex bg-white rounded-lg">
      <div className="flex flex-col w-0 h-dvh overflow-hidden flex-[1_1_100%]">
        <div className="overflow-y-auto no-scrollbar flex-grow">
          <div className="m-4 mb-8">
            <button
              className="btn btn-accent btn-sm btn-block"
              onClick={() => {
                history(`/?new=true`)
              }}
            >
              New chat
            </button>
          </div>
          <ChatList
            className=""
            onChatClick={(chat) => {
              history(`/?chatId=${chat.id}`)
            }}
          />
        </div>
        <div className="m-4 mb-4 ">
          <button
            className="btn btn-warning btn-sm btn-block"
            onClick={async () => {
              if (signOut) {
                console.log("signOut before")
                const success = await signOut()
                console.log("signOut", success)
                if (success) {
                  notifySuccess("You are sign out")
                  console.log("signOut", notifySuccess)
                }
              }
            }}
          >
            {loadingSignOut ? (
              <span className="loading loading-spinner text-white"></span>
            ) : (
              ""
            )}
            <span className="text-white">Logout</span>
          </button>
        </div>
      </div>
    </div>
  )
}
