//import { PrimaryButton } from "../shared/components/PrimaryButton"
import React, { FC } from "react"
import { useSignOut } from "react-firebase-hooks/auth"
import { getAuth } from "firebase/auth"
import firebaseApp from "../../firebaseConfig"
import { useNavigate } from "react-router-dom"

const LogoutPage: FC = () => {
  const history = useNavigate()
  const [signOut, loadingSignOut] = useSignOut(getAuth(firebaseApp))

  return (
    <div className="w-dvw h-dvh flex items-center justify-center ">
      <div className="mb-4 ">
        <button
          className="btn btn-warning btn-sm btn-block mx-auto my-auto"
          onClick={async () => {
            if (signOut) {
              console.log("signOut before")
              const success = await signOut()
              console.log("signOut", success)
              if (success) {
                history(`/login`, { replace: true })
              }
            }
          }}
        >
          {loadingSignOut ? (
            <span className="loading loading-spinner text-white"></span>
          ) : (
            ""
          )}
          <span className="text-white">Logout</span>
        </button>
      </div>
    </div>
  )
}

export default LogoutPage
