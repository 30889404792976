import { FC } from "react"

type Props = {
  className?: string
  nLines?: number
  mRightPx?: number
  thinLine?: boolean
}

const allowedWidths = ["w-1/2", "w-2/5", "w-3/5", "w-3/5"]
const getRanmdonWidth = () =>
  allowedWidths[Math.floor(Math.random() * allowedWidths.length)]

function getClass(
  linePosition: number,
  totalLines: number,
  thinLine: boolean,
  mRightPx?: number,
): string {
  let base = "rounded-sm bg-shimmer-bg-light animate-pulse"

  const classWidth =
    mRightPx === 0 || mRightPx
      ? " mr-[" + mRightPx.toString() + "px]"
      : " " + getRanmdonWidth()

  if (linePosition !== 0) base += " mt-2"
  if (linePosition === totalLines - 1) base += classWidth
  base += thinLine ? " h-2" : " h-3"

  return base
}

const LinesShimmer: FC<Props> = ({
  className,
  nLines = 1,
  mRightPx,
  thinLine = false,
}) => {
  return (
    <div className={`${className} w-full`}>
      {[...Array(nLines)].map((_, v) => (
        <div key={v} className={getClass(v, nLines, thinLine, mRightPx)} />
      ))}
    </div>
  )
}

export { LinesShimmer }
