import { useEffect, useState } from "react"
import LoadingData from "../../shared/models/LoadingData"
import { useCustomNavigation } from "../../shared/hooks/useCustomNavigation"
import CustomError from "../../shared/exceptions/CustomError"
import ChatProvider from "../../providers/chat/ChatProvider"
import Chat from "../models/Chat"
import { Uuid } from "../../shared/models/UuidModel"
import UserChat from "../models/UserChat"
import { useAuth } from "../../auth/context/AuthContext"

export const useCreateChat = (provider: ChatProvider) => {
  const { user } = useAuth()
  const [createdChat, setCreatedChat] = useState<LoadingData<Chat>>({
    loading: true,
    data: undefined,
    error: undefined,
  })
  const { setErrorCode } = useCustomNavigation()

  function createNewChat() {
    setCreatedChat({
      loading: true,
      data: undefined,
      error: undefined,
    })
    const chatId = Uuid.random().value
    setCreatedChat({
      loading: false,
      data: new Chat(
        chatId,
        new UserChat(user?.uid ?? "", user?.displayName ?? ""),
        [],
        true,
        0,
        new Date(),
      ),
      error: undefined,
    })
    user?.getIdToken().then((token) => {
      provider
        .createChat(chatId, token)
        .then(() => {})
        .catch((error) => {
          if (error instanceof CustomError) {
            setErrorCode(error.errorCode)
          }
        })
    })
  }

  return { createdChatResponse: createdChat, createNewChat }
}
