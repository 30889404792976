import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import LoadingData from "../../shared/models/LoadingData"
import Chat from "../models/Chat"
import { useCustomNavigation } from "../../shared/hooks/useCustomNavigation"
import CustomError from "../../shared/exceptions/CustomError"
import ChatApiProvider from "../../providers/chat/ChatApiProvider"
import { BASE_URL } from "../../shared/constants/constants"
import AssistantConfig from "../models/AssistantConfig"
import {
  API_KEY_ASSISTANT_DEFAULT,
  ID_ASSISTANT_DEFAULT,
} from "../constants/AssistantConstants"
import { useAuth } from "../../auth/context/AuthContext"

type Context = ReturnType<typeof useChatsContext>
const ChatsContext = createContext<Context | undefined>(undefined)

export const ChatsProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  return (
    <ChatsContext.Provider value={useChatsContext()}>
      {children}
    </ChatsContext.Provider>
  )
}

export const useChats = () => {
  const context = useContext(ChatsContext)
  if (!context) throw Error("ChatsContext not available")
  return context
}

function useChatsContext(): {
  chatResponse: LoadingData<Chat[]>
  setChats
  assistantConfig: AssistantConfig
  setAssistantConfig
} {
  const { user } = useAuth()
  const [chats, setChats] = useState<LoadingData<Chat[]>>({
    loading: true,
    data: [],
    error: undefined,
  })
  const [assistantConfig, setAssistantConfig] = useState<AssistantConfig>(
    new AssistantConfig(ID_ASSISTANT_DEFAULT, API_KEY_ASSISTANT_DEFAULT),
  )
  const { setErrorCode } = useCustomNavigation()
  useEffect(() => {
    user?.getIdToken().then((token) => {
      setChats({
        loading: true,
        data: [],
        error: undefined,
      })
      new ChatApiProvider(BASE_URL)
        .getChats(token)
        .then((chats: Chat[]) => {
          setChats({ loading: false, data: chats, error: undefined })
        })
        .catch((error) => {
          if (error instanceof CustomError) {
            setErrorCode(error.errorCode)
          }
        })
    })
  }, [user])

  return { chatResponse: chats, setChats, assistantConfig, setAssistantConfig }
}
