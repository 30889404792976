import { toast } from "react-toastify"
import React from "react"

export const notify = (message: String, className?: string) =>
  toast.info(
    <div>
      <p className={`font-bold text-primary ${className}`}>{message}</p>
    </div>,
    {
      toastId: "notify",
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: false,
      className: () =>
        " alert bg-white mb-4 relative flex p-2 min-h-8 rounded-md justify-between overflow-hidden cursor-pointer shadow-lg",
    },
  )
export const notifyError = (message: String, className?: string) =>
  toast.info(
    <div>
      <p className={`font-bold text-primary ${className}`}>{message}</p>
    </div>,
    {
      toastId: "notify",
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: false,
      className: () =>
        " alert bg-white mb-4 relative flex p-2 min-h-8 rounded-md justify-between overflow-hidden cursor-pointer shadow-lg",
    },
  )
export const notifySuccess = (message: String, className?: string) =>
  toast.info(
    <div>
      <p className={`font-bold text-primary ${className}`}>{message}</p>
    </div>,
    {
      toastId: "notify",
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: false,
      className: () =>
        " alert bg-white mb-4 relative flex p-2 min-h-8 rounded-md justify-between overflow-hidden cursor-pointer shadow-lg",
    },
  )
