import { FC } from "react"

type Props = {
  className?: string
  text: string
  onToggled: (check: boolean) => void
}
const SidePanelItemToggle: FC<Props> = ({ className, text, onToggled }) => {
  return (
    <div className="w-52 cursor-pointer flex items-center">
      <p className="p-4 text-sm">{text}</p>
      <input
        type="checkbox"
        className="toggle toggle-sm toggle-info ml-auto mr-4"
        onChange={(e) => {
          onToggled(e.target.checked)
        }}
      />
    </div>
  )
}

export default SidePanelItemToggle
