import { Utils } from "../../shared/Utils"

export default class UserChat {
  constructor(
    public id: string,
    public name: string,
  ) {}

  toPrimitives(): any {
    return {
      _id: this.id,
      name: this.name,
    }
  }

  static fromPrimitives(primitives: any): UserChat {
    return new UserChat(Utils.normalizeId(primitives), primitives.name)
  }
}
