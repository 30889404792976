import { FC, useEffect, useRef, useState } from "react"
import { Message } from "../models/Message"
import Markdown from "react-markdown"
import { Utils } from "../../shared/Utils"
import NextStepButton from "./NextStepButton"
import { string } from "yup"

type Props = {
  message: Message
  onSelectSendMessageButton: (text: string) => void
  renderButtonActions: boolean
  onScrollBottom: () => void
}
const ChatSystemMessageBeautify: FC<Props> = ({
  message,
  onSelectSendMessageButton,
  renderButtonActions,
  onScrollBottom,
}) => {
  const [currentStep, setCurrentStep] = useState(0)

  function getNonStandardBlockValue(block: object | null): string {
    if (block) {
      let largestValue = ""
      Object.values(block).forEach((value) => {
        if (value.length > largestValue.length) {
          largestValue = value
        }
      })
      return largestValue
    }
    return ""
  }

  function getNonStandardStepValue(step: object | null): string {
    if (step) {
      let largestValue = ""
      Object.values(step).forEach((value) => {
        if (value.length > largestValue.length) {
          largestValue = value
        }
      })
      return largestValue
    }
    return ""
  }

  function getStepsValues(obj): string[] {
    const stepsToreturn: string[] = []
    if (obj.nextSteps && typeof obj.nextSteps === "object") {
      const steps = Object.values(obj.nextSteps)
      for (const step of steps) {
        if (typeof step === "string") {
          stepsToreturn.push(step)
        }
        if (typeof step === "object") {
          stepsToreturn.push(getNonStandardStepValue(step))
        }
      }
    }
    return stepsToreturn
  }

  function getBlocksValues(obj): string[] {
    if (obj.adviceBlocks && typeof obj.adviceBlocks === "object") {
      const blocksToreturn: string[] = []
      const blocks = Object.values(obj.adviceBlocks)
      for (const block of blocks) {
        if (typeof block === "object") {
          blocksToreturn.push(getNonStandardBlockValue(block))
        }
        if (typeof block === "string") {
          blocksToreturn.push(block)
        }
      }
      return blocksToreturn
    }
    if (typeof obj === "string") {
      return [obj]
    }
    return []
  }

  let steps: string[] = []
  try {
    steps = getStepsValues(
      Utils.extractJsonFromMarkdown(message.text).jsonObject,
    )
  } catch (e) {
    console.log(e)

    steps = [message.text]
  }
  let blocks: string[] = []
  try {
    const extratedText = Utils.extractJsonFromMarkdown(message.text)
    blocks = getBlocksValues(extratedText.jsonObject)
    if (extratedText.text) {
      blocks.push(extratedText.text)
    }
    console.log("blocks", blocks)
  } catch (e) {
    console.log(e)
    blocks = [message.text]
  }
  const handleNextBlock = () => {
    if (currentStep < blocks.length) {
      setCurrentStep(currentStep + 1)
    }
  }

  useEffect(() => {
    if (!renderButtonActions) {
      setCurrentStep(blocks.length - 1)
    }
  }, [renderButtonActions])

  return (
    <div key="asd" className="">
      <div className="my-8">
        <div className="flex">
          <span className="font-bold">Noah</span>
          <span className="ml-auto text-xs">
            {message.createdAt.toLocaleDateString()}
          </span>
        </div>
        <div className="flex mt-2">
          <div className="flex-1 w-0">
            <div className="flex-col">
              {blocks.map((block, index) => {
                return (
                  index <= currentStep && (
                    <div key={index} className="flex-col my-3">
                      <p className="mb-3 break-words">{block}</p>
                      {index === currentStep &&
                        index < blocks.length - 1 &&
                        renderButtonActions && (
                          <button
                            className="px-3 py-1 mb-4 cursor-pointer select-none animate-custom-bounce w-min rounded-3xl bg-secondary hover:shadow-md hover:bg-secondary-hover"
                            onClick={() => {
                              handleNextBlock()
                              Utils.delay(100).then(() => {
                                onScrollBottom()
                              })
                            }}
                          >
                            <span className="text-sm text-white md:text-md">
                              Next
                            </span>
                          </button>
                        )}
                    </div>
                  )
                )
              })}
              {currentStep >= blocks.length - 1 &&
                renderButtonActions &&
                steps.map((step) => (
                  <div key={step} className="flex my-3">
                    <NextStepButton
                      text={step}
                      onClick={onSelectSendMessageButton}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="h-0.5 bg-divider my-6 rounded"></div>
    </div>
  )
}

export default ChatSystemMessageBeautify
