import { FC } from "react"
import SuggestionBoxNoMessageChatView from "./SuggestionBoxNoMessageChatView"
import classNames from "classnames"
import { UserRole } from "../../../user/model/UserRole"
import { useAuth } from "../../../auth/context/AuthContext"

type Props = {
  className?: string
  onSendMessage: (message: string) => void
  onUploadCarDeal: () => void
}

const NoMessagesChatView: FC<Props> = ({
  onSendMessage,
  onUploadCarDeal,
  className,
}) => {
  const { userBackend } = useAuth()
  const mdMr = classNames({
    "md:mr-60": userBackend?.role === UserRole.ADMIN,
    "md:mr-12": userBackend?.role !== UserRole.ADMIN,
  })
  return (
    <div
      className={`${className} flex-1 flex flex-col overflow-y-scroll h-screen  w-full no-scrollbar pb-16 overflow-y-scroll overflow-y-scroll items-center justify-center`}
    >
      <h1
        className={`text-xl font-bold mr-4 ml-4 ${mdMr} md:ml-4 mb-12 items-center justify-center`}
      >
        Hi, I'm Noah, your car-buying assistant.
      </h1>
      <div
        className={`grid grid-cols-1 md:grid-cols-2 w-[300px] md:w-[620px] mr-4 ml-4 ${mdMr} md:ml-4 mb-4`}
      >
        <SuggestionBoxNoMessageChatView
          title="Learn about the car buying process"
          subtitle="Break down all the key steps"
          onClick={() => {
            onSendMessage("Learn about the car buying process")
          }}
        />
        <SuggestionBoxNoMessageChatView
          title="Explore financing vs leasing"
          subtitle="Determine what's right for you"
          onClick={() => {
            onSendMessage("Explore financing vs leasing")
          }}
        />
        <SuggestionBoxNoMessageChatView
          title="Get tips on negotiating quotes"
          subtitle="Become an expert negotiator"
          onClick={() => {
            onSendMessage("Get tips on negotiating quotes")
          }}
        />
        <SuggestionBoxNoMessageChatView
          title="Upload a quote"
          subtitle="I'll analyze your offer!"
          onClick={onUploadCarDeal}
        />
      </div>
    </div>
  )
}

export default NoMessagesChatView
