import { useFormik } from "formik"
import * as Yup from "yup"
import { notify } from "../../shared/components/toasts/ToastUtils"
import React, { useState } from "react"
import { Uuid } from "../../shared/models/UuidModel"
import { useChats } from "./ChatsContext"
import AssistantConfig from "../models/AssistantConfig"
import LoadingData from "../../shared/models/LoadingData"
import ChatCarDeal from "../models/ChatCarDeal"
import ChatProvider from "../../providers/chat/ChatProvider"
import CustomError from "../../shared/exceptions/CustomError"
import {
  PROMPT_GET_ADVISE,
  PROMPT_RETURN_JSON,
} from "../constants/AssistantConstants"
import { useAuth } from "../../auth/context/AuthContext"
import Chat from "../models/Chat"

export const useCarDealUploadImage = (
  chatId: string,
  provider: ChatProvider,
) => {
  const { assistantConfig } = useChats()
  const [carDealResponse, setChatResponse] = useState<LoadingData<Chat>>({
    loading: false,
    data: undefined,
    error: undefined,
  })
  const [image, setImage] = React.useState<File | undefined>(undefined)
  const { user } = useAuth()

  const formik = useFormik({
    initialValues: {
      car_deal_image: "",
    },
    validationSchema: Yup.object({
      car_deal_image: Yup.string().required("* Required"),
    }),
    onSubmit: async (values) => {
      setChatResponse({
        loading: true,
        data: undefined,
        error: undefined,
      })
      user?.getIdToken().then(async (token) => {
        try {
          const chat = await provider.askForDealAdvise(
            chatId,
            PROMPT_RETURN_JSON,
            PROMPT_GET_ADVISE,
            assistantConfig.id,
            assistantConfig.apiKey,
            image!,
            token,
          )
          setChatResponse({
            loading: false,
            data: chat,
            error: undefined,
          })
          notify("Created")
        } catch (e) {
          setChatResponse({
            loading: false,
            data: undefined,
            error: undefined,
          })
          console.log("useCreateNewDeal error")
          notify("Unexpected error")
        }
      })
    },
  })

  return {
    formik,
    carDeal: carDealResponse,
    setImage,
  }
}
