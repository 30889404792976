import ChatCarDealIteration from "./ChatCarDealIteration"

export default class MessageMetadata {
  constructor(readonly carDeal?: ChatCarDealIteration) {}

  public toPrimitives() {
    return {
      carDeal: this.carDeal ? this.carDeal.toPrimitives() : undefined,
    }
  }

  static fromPrimitives(primitives: any): MessageMetadata {
    return new MessageMetadata(
      primitives.carDeal
        ? ChatCarDealIteration.fromPrimitives(primitives.carDeal)
        : undefined,
    )
  }
}
