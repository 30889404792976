import React, { FC, useEffect } from "react"
import { useCarDealUploadImage } from "../hooks/useCarDealUploadImage"
import { BASE_URL } from "../../shared/constants/constants"
import ChatApiProvider from "../../providers/chat/ChatApiProvider"
import ChatCarDeal from "../models/ChatCarDeal"
import Chat from "../models/Chat"

type Props = {
  className?: string
  chatId: string
  onUploaded: (chat: Chat) => void
}
const CarDealUploadImageDialog: FC<Props> = ({
  onUploaded,
  chatId,
  className,
}) => {
  const { formik, setImage, carDeal } = useCarDealUploadImage(
    chatId,
    new ChatApiProvider(BASE_URL),
  )

  useEffect(() => {
    if (carDeal.data) {
      onUploaded(carDeal.data)
    }
  }, [carDeal.data])

  return (
    <dialog id="car_deal_upload_image" className="modal">
      <div className="modal-box">
        <h3 className="mb-6 text-lg font-bold">Upload car deal</h3>

        <form onSubmit={formik.handleSubmit}>
          <input
            type="file"
            className="w-full file-input file-input-bordered file-input-secondary"
            onChange={(event) => {
              if (event.currentTarget?.files && event.currentTarget?.files[0]) {
                formik.values.car_deal_image =
                  event.currentTarget!.files![0].name
                setImage(event.currentTarget!.files![0])
              } else {
                formik.values.car_deal_image = ""
                setImage(undefined)
              }
            }}
          />
          <div className="label">
            {formik.touched.car_deal_image && formik.errors.car_deal_image ? (
              <div className="label">
                <p className="label-text-alt text-error">
                  {formik.errors.car_deal_image}
                </p>
              </div>
            ) : null}
          </div>

          <button
            className="btn h-10 btn-primary  mt-8 w-full !py-0"
            type="submit"
            disabled={carDeal.loading}
          >
            {carDeal.loading ? (
              <span className="text-white loading loading-spinner"></span>
            ) : (
              ""
            )}
            <p className="font-bold text-white ">Upload</p>
          </button>
        </form>
      </div>

      {!carDeal.loading && (
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      )}
    </dialog>
  )
}

export default CarDealUploadImageDialog
