import React, { FC } from "react"
import { ToastContainer } from "react-toastify"

type Props = {
  className?: string
}

const Toast: FC<Props> = ({ className }) => {
  return (
    <ToastContainer
      toastClassName={() =>
        " alert bg-white mb-4 relative flex p-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
      }
      bodyClassName={() => "flex text-sm p-3 "}
    />
  )
}

export { Toast }
