import React, { FC } from "react"
import { Navigate } from "react-router-dom"
import MainLayout from "../mainLayout/MainLayout"
import { useAuth } from "../../../auth/context/AuthContext"

type Props = {
  component: JSX.Element
}

const PrivateRoute: FC<Props> = ({ component }) => {
  const { user, loading } = useAuth()
  return user ? (
    <MainLayout>{component}</MainLayout>
  ) : loading ? null : (
    <Navigate to="/login" replace />
  )
}

export default PrivateRoute
