import React, { FC, ReactNode, useEffect } from "react"
import Header from "../header/Header"
import { SideMenu } from "../navMenu/sideMenu/SideMenu"
import { Toast } from "../toasts/Toast"
type Props = {
  children: ReactNode
}

const MainLayout: FC<Props> = ({ children }) => {
  /*const setVh = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  useEffect(() => {
    setVh() // Set the initial value
    window.addEventListener("resize", setVh) // Update on resize

    return () => {
      window.removeEventListener("resize", setVh) // Cleanup
    }
  }, [])*/
  return (
    <>
      <div className="flex">
        <div id="baseScroll" className="flex-1 lg:mb-0">
          <Toast />
          {children}
        </div>
      </div>
    </>
  )
}

export default MainLayout
