import React, { FC, useEffect } from "react"
import { useAssistantConfig } from "../hooks/useAssistantConfig"

type Props = {
  className?: string
}
const UpdateAssistantConfigDialog: FC<Props> = ({ className }) => {
  const { formik, success } = useAssistantConfig()

  return (
    <dialog id="assistant_config" className="modal">
      <div className="modal-box">
        <h3 className="font-bold text-lg mb-6">Assistant config</h3>

        <form onSubmit={formik.handleSubmit}>
          <label className="form-control mb-2">
            <div className="label">
              <span className="label-text font-semibold">Assistant id</span>
            </div>
            <input
              className="input input-bordered"
              placeholder="Id"
              {...formik.getFieldProps("assistant_id")}
            ></input>
            <div className="label">
              {formik.touched.assistant_id && formik.errors.assistant_id ? (
                <div className="label">
                  <p className="label-text-alt text-error">
                    {formik.errors.assistant_id}
                  </p>
                </div>
              ) : null}
            </div>
          </label>
          <label className="form-control mb-6">
            <div className="label">
              <span className="label-text font-semibold">
                Assistant API key
              </span>
            </div>
            <input
              className="input input-bordered"
              placeholder="API key"
              {...formik.getFieldProps("assistant_api_key")}
            ></input>
            <div className="label">
              {formik.touched.assistant_api_key &&
              formik.errors.assistant_api_key ? (
                <div className="label">
                  <p className="label-text-alt text-error">
                    {formik.errors.assistant_api_key}
                  </p>
                </div>
              ) : null}
            </div>
          </label>

          <button
            className="btn h-10 btn-primary w-full !py-0"
            type="submit"
            onClick={() => {
              ;(
                document.getElementById("assistant_config") as HTMLFormElement
              ).close()
            }}
          >
            <p className="text-white font-bold ">Set</p>
          </button>
        </form>
      </div>

      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  )
}

export default UpdateAssistantConfigDialog
