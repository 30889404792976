import { useFormik } from "formik"
import * as Yup from "yup"
import {
  notify,
  notifySuccess,
} from "../../shared/components/toasts/ToastUtils"
import InvalidCredentialsError from "../../shared/exceptions/InvalidCredentialsError"
import React from "react"
import { useAuth } from "../../auth/context/AuthContext"
import AuthProvider from "../provider/AuthProvider"
import { Uuid } from "../../shared/models/UuidModel"
import GenericWithTextError from "../../shared/exceptions/GenericWithTextError"

export const useSignUp = (provider: AuthProvider) => {
  const { user } = useAuth()
  const [loading, setLoading] = React.useState(false)

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
      role: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("* Required"),
      password: Yup.string().required("* Required"),
      name: Yup.string().required("* Required"),
      role: Yup.string().required("* Required"),
    }),
    onSubmit: (values) => {
      user?.getIdToken().then((token) => {
        setLoading(true)
        provider
          .signUp(
            token,
            Uuid.random().value,
            values.email,
            values.password,
            values.name,
            values.role,
          )
          .then(() => {
            setLoading(false)
            formik.resetForm()
            notifySuccess("User created")
          })
          .catch((e: Error) => {
            console.log(e)
            setLoading(false)
            if (e instanceof GenericWithTextError) {
              notify(e.message)
              return
            }
            notify("Unexpected error")
          })
      })
    },
  })

  return {
    formik,
    loading,
  }
}
