import React, { FC, useEffect, useState } from "react"
import ChatList from "../components/ChatList"
import Chat from "../models/Chat"
import ChatMessages from "../components/ChatMessages"
import InputSendMessage from "../components/InputSendMessage"
import ChatApiProvider from "../../providers/chat/ChatApiProvider"
import { BASE_URL } from "../../shared/constants/constants"
import Interaction from "../models/Interaction"
import { Uuid } from "../../shared/models/UuidModel"
import { useChats } from "../hooks/ChatsContext"
import { useCreateChat } from "../hooks/useCreateChat"
import classNames from "classnames"
import NoMessagesChatView from "../components/NoMessagesChat/NoMessagesChatView"
import { useSendMessage } from "../hooks/useSendMessage"
import { useSignOut } from "react-firebase-hooks/auth"
import { getAuth } from "firebase/auth"
import firebaseApp from "../../firebaseConfig"
import { notifySuccess } from "../../shared/components/toasts/ToastUtils"
import { useNavigate, useSearchParams } from "react-router-dom"
import useWindowDimensions from "../../shared/hooks/useWindowDimensions"
import { ScreenSize } from "../../shared/constants/ScreenSizes"
import { Utils } from "../../shared/Utils"
import { useAuth } from "../../auth/context/AuthContext"
import { UserRole } from "../../user/model/UserRole"

export const ChatPage: FC = () => {
  const history = useNavigate()
  const { width } = useWindowDimensions()
  function updateList(chat: Chat): void {
    const chatsAux = chatResponse.data?.map((chatItem: Chat) => {
      if (chatItem.id === chat.id) {
        console.log("updateList1", chatItem.interactions)
        chatItem.carDeal = chat.carDeal
        chatItem.interactions = [...chat.interactions]
        console.log("updateList2", chatItem.interactions)
      }
      return chatItem
    })
    console.log("chatsAux", chatsAux)
    setChats({
      loading: false,
      data: chatsAux,
      error: undefined,
    })
  }

  const [showChatHistory, setShowChatHistory] = useState(false)
  const [showChatDetails, setShowChatDetails] = useState(false)
  const [selectedChat, setSelectedChat] = useState<{
    chat: Chat
    loading: boolean
  }>()
  const { createdChatResponse, createNewChat } = useCreateChat(
    new ChatApiProvider(BASE_URL),
  )
  const { setChats, chatResponse } = useChats()
  const [signOut, loadingSignOut] = useSignOut(getAuth(firebaseApp))

  const { sendMessage, userMessage, assistantMessageResponse } = useSendMessage(
    undefined,
    new ChatApiProvider(BASE_URL),
  )

  useEffect(() => {
    if (userMessage) {
      console.log("onSendMessage", selectedChat)
      if (selectedChat) {
        selectedChat.chat.interactions.push(
          new Interaction(
            Uuid.random().value,
            userMessage,
            undefined,
            0,
            new Date(),
          ),
        )
        setSelectedChat({
          chat: Chat.fromPrimitives(selectedChat.chat.toPrimitives()),
          loading: true,
        })
      }
    }
  }, [userMessage])

  useEffect(() => {
    console.log(
      "NoMessagesChatView assistantMessageResponse",
      assistantMessageResponse,
    )
    if (assistantMessageResponse.data) {
      console.log("onReceivedAssistantMessage", assistantMessageResponse.data)
      if (selectedChat && assistantMessageResponse.data) {
        selectedChat.chat.interactions[
          selectedChat.chat.interactions.length - 1
        ].response = assistantMessageResponse.data

        setSelectedChat({
          chat: Chat.fromPrimitives(selectedChat.chat.toPrimitives()),
          loading: false,
        })
        updateList(selectedChat.chat)
      }
    }
  }, [assistantMessageResponse])

  useEffect(() => {
    console.log("useEffect createdChatResponse.data", createdChatResponse.data)
    if (createdChatResponse.data) {
      setChats((prevChats) => {
        return {
          ...prevChats,
          data: [createdChatResponse.data!, ...prevChats.data],
        }
      })
    }

    setSelectedChat({
      chat: createdChatResponse.data!,
      loading: false,
    })
  }, [createdChatResponse.data])

  const [searchParams] = useSearchParams()
  const chatIdParam = searchParams.get("chatId")
  const newChatParam = searchParams.get("new")
  useEffect(() => {
    if (chatIdParam) {
      const chat = chatResponse.data?.find(
        (chat: Chat) => chat.id === chatIdParam,
      )
      if (chat) {
        setSelectedChat({ chat, loading: false })
      }
      return
    } else {
      if (
        Utils.isThinnerEqualThan(width, ScreenSize.md) &&
        !selectedChat?.chat &&
        newChatParam !== "true"
      ) {
        history(`/chats`, { replace: true })
      } else if (
        !Utils.isThinnerEqualThan(width, ScreenSize.md) &&
        !selectedChat?.chat &&
        newChatParam !== "true" &&
        chatResponse?.data?.length &&
        chatResponse.data.length > 0
      ) {
        history(`/?chatId=${chatResponse!.data![0].id}`, {
          replace: true,
        })
      }
    }
  }, [chatIdParam, chatResponse.data])

  useEffect(() => {
    try {
      if (newChatParam === "true") {
        console.log("createNewChat")
        createNewChat()
      }
    } catch (error) {}
  }, [newChatParam])

  const sidePanelClass = classNames({
    "flex flex-col w-0 h-dvh md:w-56 overflow-hidden ": true,
    "flex-[1_1_100%] md:flex-none": showChatHistory || !selectedChat?.chat,
  })

  console.log(selectedChat?.chat, !selectedChat?.chat, "selectedChat")
  return (
    <div id="baseScroll" className="flex bg-white rounded-lg">
      <div className={sidePanelClass}>
        <div className="overflow-y-auto no-scrollbar flex-grow">
          <div className="m-4 mb-8">
            <button
              className="btn btn-accent btn-sm btn-block"
              onClick={() => {
                setShowChatHistory(false)
                createNewChat()
              }}
            >
              New chat
            </button>
          </div>
          <ChatList
            className=""
            onChatClick={(chat) => {
              //setSelectedChat({ chat, loading: selectedChat?.loading ?? false })
              history(`/?chatId=${chat.id}`)
              setShowChatHistory(false)
            }}
          />
        </div>
        <div className="m-4 mb-4 ">
          <button
            className="btn btn-warning btn-sm btn-block"
            onClick={async () => {
              if (signOut) {
                console.log("signOut before")
                const success = await signOut()
                console.log("signOut", success)
                if (success) {
                  notifySuccess("You are sign out")
                  console.log("signOut", notifySuccess)
                }
              }
            }}
          >
            {loadingSignOut ? (
              <span className="loading loading-spinner text-white"></span>
            ) : (
              ""
            )}
            <span className="text-white">Logout</span>
          </button>
        </div>
      </div>
      <div className="relative flex-col flex-1 overflow-y-scroll no-scrollbar ">
        {selectedChat?.chat && selectedChat?.chat?.interactions?.length > 0 && (
          <ChatMessages
            className="flex-1 overflow-y-scroll h-dvh no-scrollbar pb-16"
            chat={selectedChat}
            showChatDetails={showChatDetails}
            onSendMessage={(text, chatId) => {
              console.log("onSendMessage", selectedChat, chatId)
              if (selectedChat) {
                selectedChat.chat.interactions.push(
                  new Interaction(
                    Uuid.random().value,
                    text,
                    undefined,
                    0,
                    new Date(),
                  ),
                )
                setSelectedChat({
                  chat: Chat.fromPrimitives(selectedChat.chat.toPrimitives()),
                  loading: true,
                })
              }
            }}
            onAssistantMessage={(assistantMessage) => {
              if (selectedChat && assistantMessage) {
                selectedChat.chat.interactions[
                  selectedChat.chat.interactions.length - 1
                ].response = assistantMessage

                setSelectedChat({
                  chat: Chat.fromPrimitives(selectedChat.chat.toPrimitives()),
                  loading: false,
                })
                updateList(selectedChat.chat)
              }
            }}
          />
        )}
        {selectedChat?.chat?.interactions === undefined ||
          (selectedChat?.chat?.interactions?.length === 0 && (
            <NoMessagesChatView
              onUploadCarDeal={() => {
                ;(
                  document.getElementById(
                    "car_deal_upload_image",
                  ) as HTMLFormElement
                ).showModal()
              }}
              onSendMessage={(message: string) => {
                sendMessage(message, selectedChat?.chat?.id)
              }}
            />
          ))}
        {selectedChat?.chat && (
          <InputSendMessage
            chatId={selectedChat?.chat?.id ?? ""}
            className="absolute bottom-0 w-full"
            onChange={() => {}}
            onSendMessage={(userMessage) => {
              console.log(
                "onSendMessage",
                "selectedChat",
                selectedChat?.chat,
                "userMessage",
                userMessage,
              )
              if (selectedChat && userMessage) {
                selectedChat.chat.interactions.push(
                  new Interaction(
                    Uuid.random().value,
                    userMessage,
                    undefined,
                    0,
                    new Date(),
                  ),
                )
                console.log(
                  "if (selectedChat && userMessage) {",
                  selectedChat.chat,
                )
                console.log(
                  "selectedChat.chat.toPrimitives()",
                  selectedChat.chat.toPrimitives(),
                )
                setSelectedChat({
                  chat: Chat.fromPrimitives(selectedChat.chat.toPrimitives()),
                  loading: true,
                })
              }
            }}
            onAssistantMessage={(assistantMessage) => {
              console.log(
                "onAssistantMessage",
                "selectedChat",
                selectedChat?.chat,
                "assistantMessage",
                assistantMessage,
              )
              if (selectedChat && assistantMessage) {
                selectedChat.chat.interactions[
                  selectedChat.chat.interactions.length - 1
                ].response = assistantMessage

                console.log(
                  "if (selectedChat && assistantMessage) {",
                  selectedChat.chat,
                )
                console.log(
                  "selectedChat.chat.toPrimitives()",
                  selectedChat.chat.toPrimitives(),
                )
                setSelectedChat({
                  chat: Chat.fromPrimitives(selectedChat.chat.toPrimitives()),
                  loading: false,
                })
                updateList(selectedChat.chat)
              }
            }}
            onChatHistory={() => {
              //setShowChatHistory(true)
              history(`/chats`)
            }}
            onChatDetails={(show) => {
              setShowChatDetails(show)
            }}
            showChatDetails={showChatDetails}
            onChatDealUploaded={(chat: Chat) => {
              updateList(chat)
            }}
          />
        )}
      </div>
    </div>
  )
}
