import { FC } from "react"
import { Message } from "../models/Message"
import Markdown from "react-markdown"

type Props = {
  message: Message
}
const ChatUserCarDealMessage: FC<Props> = ({ message }) => {
  return (
    <div key={message.id} className="">
      <div className="my-8">
        <div className="flex items-center">
          <span className="font-bold">You</span>
          <span className="ml-auto text-xs">
            {message.createdAt.toLocaleDateString()}
          </span>
        </div>
        <div className="flex mt-2">
          <div className="flex-1 w-0">
            <img
              className="rounded-md w-[600px]"
              src={`data:image/jpeg;base64,${message.metadata?.carDeal?.image}`}
            />
          </div>
        </div>
      </div>
      <div className="h-0.5 bg-divider my-6 rounded"></div>
    </div>
  )
}

export default ChatUserCarDealMessage
