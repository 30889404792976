export class Utils {
  static async copyToClipboard(content): Promise<void> {
    if (window.isSecureContext && navigator.clipboard) {
      await navigator.clipboard.writeText(content)
    } else {
      Utils.unsecuredCopyToClipboard(content)
    }
  }

  static unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      document.execCommand("copy")
    } catch (err) {
      console.error("Unable to copy to clipboard", err)
    }
    document.body.removeChild(textArea)
  }

  static normalizeId(objectWithId: any): string {
    let id = ""
    if (objectWithId._id) {
      id = objectWithId._id
    } else {
      if (typeof objectWithId.id == "string") {
        id = objectWithId.id
      } else {
        id = objectWithId.id?.value
      }
    }
    return id
  }
  static normalizeUserId(objectWithId: any): string {
    let userId = ""
    if (typeof objectWithId.userId == "string") {
      userId = objectWithId.userId
    } else {
      userId = objectWithId.userId?.value
    }
    return userId
  }
  static extractJsonFromMarkdown(markdownString): {
    jsonObject: any
    text: string | null
  } {
    const jsonPart = markdownString
      .replace(/```json\n|\n```/g, "")
      .replace(/\\/g, "\\\\")
      .replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f")
    const jsonMatch = jsonPart.match(/\{[\s\S]*\}/)
    const json = jsonMatch ? jsonMatch[0] : null
    const jsonObject = json ? JSON.parse(json) : {}
    const textOutsideJson = jsonPart.replace(/\{[\s\S]*\}/, "").trim()
    try {
      console.log("jsonObject", jsonObject)
      return { jsonObject: jsonObject, text: textOutsideJson }
    } catch (error) {
      console.error("Error al parsear JSON:", error)
      return {
        jsonObject: null,
        text: null,
      }
    }
  }

  static delay(ms: number) {
    return new Promise((res) => setTimeout(res, ms))
  }

  static isWidderThan(currentWidth: number, withToCompare: number): boolean {
    return currentWidth > withToCompare
  }

  static isThinnerEqualThan(
    currentWidth: number,
    withToCompare: number,
  ): boolean {
    return currentWidth <= withToCompare
  }
}
