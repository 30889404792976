import React, { FC, useEffect } from "react"
import ChatCarDeal from "../models/ChatCarDeal"
import { UUid } from "../../shared/components/Uuid/Uuid"
import { CopyIcon } from "../../assets/CopyIcon"
import { Utils } from "../../shared/Utils"
import { notifySuccess } from "../../shared/components/toasts/ToastUtils"
import Markdown from "react-markdown"
import { Message } from "../models/Message"
import { AssistantMessage } from "../models/AssistantMessage"

type Props = {
  className?: string
  carDeal: ChatCarDeal | undefined
}
const ChatCarDealDialog: FC<Props> = ({ carDeal, className }) => {
  return (
    <dialog id="chat_car_deal_details" className="modal">
      {carDeal && (
        <div className="modal-box !max-w-[1000px] xl:!max-w-[2000px]">
          <div className="flex">
            <h3 className="font-bold text-lg mb-6">Car deal</h3>
            <div className="ml-auto">
              <UUid uuid={carDeal.id} className={"font-bold text-lg"} />
            </div>
          </div>
          <div className="h-0.5 bg-divider mb-6"></div>

          {carDeal.iterations.length > 0 && (
            <div className={` ${className} px-4 mt-4 mb-6`}>
              <div className="flex mb-2 items-center">
                <UUid
                  uuid={carDeal.iterations[0].id}
                  className={"text-lg font-bold text-text"}
                />
                <span className="text-xs font-semibold ml-auto">
                  {carDeal.iterations[0].createdAt.toLocaleDateString()}
                </span>
                {/*
        <span className="text-sm text-text font-bold">{iteration.id}</span>
*/}
              </div>
              <div className="flex flex-col mb-8">
                <span className="text-xs text-text-secondary mb-1">
                  VIN: {carDeal.iterations[0].vin}
                </span>
              </div>
              <div className="mb-2">
                <div className="mb-4">
                  <img
                    className="rounded w-[600px]"
                    src={`data:image/jpeg;base64,${carDeal.iterations[0].image}`}
                  />
                </div>
                <div>
                  <div className="collapse collapse-arrow bg-divider mb-2">
                    <input type="checkbox" className="peer" />
                    <div className="flex collapse-title">
                      <p className="text-sm self-center">
                        Raw Image LLM Response
                      </p>
                      <CopyIcon
                        onClick={() => {
                          Utils.copyToClipboard(
                            carDeal!.iterations[0].rawImageLLMResponse,
                          ).then(() => {
                            notifySuccess("Copied to clipboard")
                          })
                        }}
                        className="z-50 h-3 ml-4 fill-current cursor-pointer self-center ml-auto"
                      />
                    </div>
                    <div className="collapse-content font-medium">
                      <Markdown
                        className="markdown"
                        children={carDeal.iterations[0].rawImageLLMResponse}
                      >
                        {}
                      </Markdown>
                    </div>
                  </div>
                  <div className="collapse collapse-arrow bg-divider mb-2">
                    <input type="checkbox" className="peer" />
                    <div className="flex collapse-title">
                      <div className="text-sm self-center">
                        Car details JSON NO normalized
                      </div>
                      <CopyIcon
                        onClick={() => {
                          Utils.copyToClipboard(
                            JSON.stringify(
                              carDeal!.iterations[0]
                                .carDealDetailsJsonNoNormalized,
                              null,
                              2,
                            ),
                          ).then(() => {
                            notifySuccess("Copied to clipboard")
                          })
                        }}
                        className="z-50 h-3 ml-4 fill-current cursor-pointer self-center ml-auto"
                      />
                    </div>
                    <div className="collapse-content font-medium">
                      <Markdown
                        className="markdown"
                        children={
                          "```json\n" +
                          JSON.stringify(
                            carDeal.iterations[0]
                              .carDealDetailsJsonNoNormalized,
                            null,
                            2,
                          ) +
                          "\n```"
                        }
                      >
                        {}
                      </Markdown>
                    </div>
                  </div>
                  <div className="collapse collapse-arrow bg-divider mb-2">
                    <input type="checkbox" className="peer" />
                    <div className="flex collapse-title">
                      <div className="text-sm self-center">
                        Car details JSON normalized
                      </div>
                      <CopyIcon
                        onClick={() => {
                          Utils.copyToClipboard(
                            JSON.stringify(
                              carDeal!.iterations[0]
                                .carDealDetailsJsonNormalized,
                              null,
                              2,
                            ),
                          ).then(() => {
                            notifySuccess("Copied to clipboard")
                          })
                        }}
                        className="z-50 h-3 ml-4 fill-current cursor-pointer self-center ml-auto"
                      />
                    </div>
                    <div className="collapse-content font-medium">
                      <Markdown
                        className="markdown"
                        children={
                          "```json\n" +
                          JSON.stringify(
                            carDeal.iterations[0].carDealDetailsJsonNormalized,
                            null,
                            2,
                          ) +
                          "\n```"
                        }
                      >
                        {}
                      </Markdown>
                    </div>
                  </div>
                  <div className="collapse collapse-arrow bg-divider mb-6">
                    <input type="checkbox" className="peer" />
                    <div className="flex collapse-title">
                      <div className="text-sm self-center">Vin data</div>
                      <CopyIcon
                        onClick={() => {
                          Utils.copyToClipboard(
                            JSON.stringify(
                              carDeal!.iterations[0].vinStructured,
                              null,
                              2,
                            ),
                          ).then(() => {
                            notifySuccess("Copied to clipboard")
                          })
                        }}
                        className="z-50 h-3 ml-4 fill-current cursor-pointer self-center ml-auto"
                      />
                    </div>
                    <div className="collapse-content font-medium">
                      <Markdown
                        className="markdown"
                        children={
                          "```json\n" +
                          JSON.stringify(
                            carDeal.iterations[0].vinStructured,
                            null,
                            2,
                          ) +
                          "\n```"
                        }
                      >
                        {}
                      </Markdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  )
}

export default ChatCarDealDialog
