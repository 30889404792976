import { FC } from "react"
import { Message } from "../models/Message"
import Markdown from "react-markdown"

type Props = {
  text: string
  onClick: (text: string) => void
}
const NextStepButton: FC<Props> = ({ text, onClick }) => {
  return (
    <div
      className="py-2 px-6 rounded-3xl cursor-pointer bg-secondary hover:shadow-md select-none hover:bg-secondary-hover"
      key={text}
      onClick={() => onClick(text)}
    >
      <span className="text-white text-sm md:text-md">{text}</span>
    </div>
  )
}

export default NextStepButton
