import TokenInvalidError from "../exceptions/TokenInvalidError"
import BadRequestError from "../exceptions/BadRequestError"
import APIResponseData from "../models/APIResponseData"
import ForbiddenError from "../exceptions/ForbiddenError"
import { JwtInfo } from "../models/user/JwtInfo"

export default class ApiProvider {
  constructor(protected baseUrl) {}

  getHeaders(token?: string): Headers {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    if (token) headers.append("Authorization", "Bearer " + token)
    return headers as Headers
  }

  getHeadersFormData(token?: string): Headers {
    const headers = new Headers()
    if (token) headers.append("Authorization", "Bearer " + token)
    return headers as Headers
  }
  getCriteriaParams(
    filters: { key: string; operator: string; value: string }[],
  ): string {
    if (filters.length === 0) {
      return ""
    }

    const queryParams = filters.map((filter, index) => {
      return `?filters[${index}][field]=${filter.key}&filters[${index}][operator]=${filter.operator}&filters[${index}][value]=${filter.value}`
    })

    return `?${queryParams.join("&")}`
  }

  getOrderParams(orderField: string, orderDirection: string): string {
    return `orderBy=${orderField}&order=${orderDirection}`
  }

  async refreshToken(): Promise<JwtInfo | null> {
    return fetch(`${this.baseUrl}refresh`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    }).then(async (response) => {
      await this.checkErrors(response)
      return response.json() as Promise<JwtInfo>
    })
  }

  isTokenExpiredError(response: Response): boolean {
    return response.status === 401 || response.status === 403
  }
  checkErrors(response: Response) {
    console.log("checkErrors", response)
    if (response.status === 401 || response.status === 403) {
      console.log("TokenInvalidError()")
      throw new TokenInvalidError()
    }
    if (response.status > 399) {
      console.log("BadRequest()")
      throw new BadRequestError()
    }
    console.log("NO ERRORES")
  }

  async getResponseOrThrown<T>(response: Response): Promise<T> {
    const responseRaw = (await response.json()) as APIResponseData<T>
    if (!responseRaw.error) return responseRaw.data!
    throw new Error()
  }
}
