import ApiProvider from "../../shared/providers/ApiProvider"
import UserProvider from "./UserProvider"
import UserCustom from "../../user/model/User"

export default class UserApiProvider
  extends ApiProvider
  implements UserProvider
{
  me(token: string): Promise<UserCustom> {
    return fetch(`${this.baseUrl}api/v1/me`, {
      method: "GET",
      headers: this.getHeaders(token),
    }).then(async (response) => {
      super.checkErrors(response)
      const user = await super.getResponseOrThrown<UserCustom>(response)
      return UserCustom.fromPrimitives(user)
    })
  }
}
