import { Message } from "./Message"

export enum Feedback {
  UNDEFINED = 0,
  POSITIVE = 1,
  NEGATIVE = 2,
}

export class AssistantMessage extends Message {
  constructor(
    id: string,
    text: string,
    public cost: number,
    readonly feedback: Feedback = Feedback.UNDEFINED,
    readonly comment: string = "",
  ) {
    super(id, text, "system")
  }
  toPrimitives() {
    return {
      ...super.toPrimitives(),
      cost: this.cost,
      feedback: this.feedback,
      comment: this.comment,
    }
  }

  static fromPrimitives(primitives: any): AssistantMessage {
    let id = ""
    if (primitives._id) {
      id = primitives._id.toString()
    } else {
      if (typeof primitives.id == "string") {
        id = primitives.id
      } else {
        id = primitives.id.value
      }
    }
    return new AssistantMessage(
      id,
      primitives.text,
      primitives.cost,
      primitives.feedback,
      primitives.comment,
    )
  }
}
