//import { PrimaryButton } from "../shared/components/PrimaryButton"
import React, { FC } from "react"
import { useLogin } from "./hooks/useLogin"
import { EyeThinIcon } from "../assets/EyeThinIcon"
import { EyeOffThinIcon } from "../assets/EyeOffThinIcon"
import { Toast } from "../shared/components/toasts/Toast"

const LoginPage: FC = () => {
  const [passwordShown, setPasswordShown] = React.useState(false)
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }

  const { formik, loading } = useLogin()

  return (
    <>
      <div className="flex h-screen">
        <div className="m-auto md:w-96 w-full pl-4 pr-4 ">
          <div className="flex justify-center">
            <div>
              <a href="/"></a>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full self-center px-6 py-8 rounded shadow-2xl">
              <div className="mt-4">
                <label className="form-control w-full max-w-xs">
                  <input
                    type={"text"}
                    placeholder="Email"
                    className="input input-bordered w-full max-w-xs"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit()
                      }
                    }}
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="label">
                      <p className="label-text-alt text-error">
                        {formik.errors.email}
                      </p>
                    </div>
                  ) : null}
                </label>
              </div>
              <div className="mt-4">
                <label className="form-control w-full max-w-xs">
                  <div className="flex">
                    <input
                      type={passwordShown ? "text" : "password"}
                      placeholder="Password"
                      className="input input-bordered w-full max-w-xs mr-2"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          formik.handleSubmit()
                        }
                      }}
                      {...formik.getFieldProps("password")}
                    />

                    <label className="swap text-9xl">
                      {/* this hidden checkbox controls the state */}
                      <input
                        type="checkbox"
                        onChange={togglePasswordVisibility}
                      />
                      <div className="swap-on text-lg">
                        <EyeOffThinIcon className={`h-6 text-primary`} />
                      </div>
                      <div className="swap-off text-lg">
                        <EyeThinIcon className={`h-6 text-primary`} />
                      </div>
                    </label>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="label">
                      <p className="label-text-alt text-error">
                        {formik.errors.password}
                      </p>
                    </div>
                  ) : null}
                </label>
              </div>

              <button
                className="btn h-10 btn-primary  mt-8 w-full !py-0"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <span className="loading loading-spinner text-white"></span>
                ) : (
                  ""
                )}
                <p className="text-white font-bold ">Login</p>
              </button>
            </div>
          </form>
        </div>

        <Toast />
      </div>
    </>
  )
}

export default LoginPage
