import ApiProvider from "../../shared/providers/ApiProvider"
import ChatProvider from "./ChatProvider"
import Chat from "../../chat/models/Chat"
import { AssistantMessage } from "../../chat/models/AssistantMessage"

export default class ChatApiProvider
  extends ApiProvider
  implements ChatProvider
{
  private refreshTokenIfNeeded: boolean = true

  getChats(token: string): Promise<Chat[]> {
    return fetch(
      `${this.baseUrl}api/v1/chat?${super.getOrderParams("createdAt", "desc")}`,
      {
        method: "GET",
        headers: this.getHeaders(token),
      },
    ).then(async (response) => {
      super.checkErrors(response)
      return (await super.getResponseOrThrown<Chat[]>(response)).map((item) => {
        return Chat.fromPrimitives(item)
      })
    })
  }

  getChat(chatId: string, token: string): Promise<Chat> {
    return fetch(
      `${this.baseUrl}api/v1/chat${super.getCriteriaParams([
        { key: "_id", operator: "=", value: chatId },
      ])}`,
      {
        method: "GET",
        headers: this.getHeaders(token),
      },
    ).then(async (response) => {
      super.checkErrors(response)
      const chats = await super.getResponseOrThrown<Chat[]>(response)
      if (chats.length === 0) {
        throw new Error("Chat not found") //TODO: Create custom error
      }
      return Chat.fromPrimitives(chats[0])
    })
  }

  sendMessage(
    chatId: string,
    question: string,
    assistantId: string,
    assistantApiKey: string,
    token: string,
  ): Promise<AssistantMessage> {
    return fetch(`${this.baseUrl}api/v1/chat/${chatId}/ask-assistant`, {
      method: "POST",
      headers: this.getHeaders(token),
      body: JSON.stringify({
        question: question,
        assistant_id: assistantId,
        assistant_api_key: assistantApiKey,
      }),
    }).then(async (response) => {
      super.checkErrors(response)
      const assistantMessage =
        await super.getResponseOrThrown<AssistantMessage>(response)
      return AssistantMessage.fromPrimitives(assistantMessage)
    })
  }
  askForDealAdvise(
    chatId: string,
    promptGetJson: string,
    promptGetAdvice: string,
    assistantId: string,
    assistantApiKey: string,
    image: File,
    token: string,
  ): Promise<Chat> {
    const body = new FormData()
    body.append("deal_image", image)
    body.append("prompt_get_json", promptGetJson)
    body.append("prompt_get_advice", promptGetAdvice)
    body.append("assistant_id", assistantId)
    body.append("assistant_api_key", assistantApiKey)
    return fetch(`${this.baseUrl}api/v1/chat/${chatId}/car-deal/advise`, {
      method: "POST",
      body,
      headers: this.getHeadersFormData(token),
    }).then(async (response) => {
      super.checkErrors(response)
      const chat = await super.getResponseOrThrown<Chat>(response)
      return Chat.fromPrimitives(chat)
    })
  }

  createChat(chatId: string, token: string): Promise<void> {
    return fetch(`${this.baseUrl}api/v1/chat/${chatId}`, {
      method: "PUT",
      headers: this.getHeaders(token),
    }).then(async (response) => {
      super.checkErrors(response)
      return await super.getResponseOrThrown<void>(response)
    })
  }
}
