import ChatCarDealIteration from "./ChatCarDealIteration"
import { Utils } from "../../shared/Utils"

export default class ChatCarDeal {
  constructor(
    public id: string,
    private userId: string,
    public iterations: ChatCarDealIteration[] = [],
    public createdAt: Date = new Date(),
  ) {}

  toPrimitives(): any {
    return {
      _id: this.id,
      userId: this.userId,
      iterations: this.iterations.map((iteration) => iteration.toPrimitives()),
      createdAt: this.createdAt,
    }
  }

  addIteration(iteration: ChatCarDealIteration) {
    this.iterations.push(iteration)
  }

  static fromPrimitives(primitives: any): ChatCarDeal {
    return new ChatCarDeal(
      Utils.normalizeId(primitives),
      Utils.normalizeUserId(primitives),
      primitives.iterations
        ? primitives.iterations.map((iteration) =>
            ChatCarDealIteration.fromPrimitives(iteration),
          )
        : [],
      new Date(primitives.createdAt),
    )
  }
}
