import { useFormik } from "formik"
import * as Yup from "yup"
import { notify } from "../../shared/components/toasts/ToastUtils"
import React, { useEffect, useState } from "react"
import ChatProvider from "../../providers/chat/ChatProvider"
import { AssistantMessage } from "../models/AssistantMessage"
import LoadingData from "../../shared/models/LoadingData"
import { Message } from "../models/Message"
import { Uuid } from "../../shared/models/UuidModel"
import { useChats } from "./ChatsContext"
import { useAuth } from "../../auth/context/AuthContext"

export const useSendMessage = (
  chatId: string | undefined,
  provider: ChatProvider,
) => {
  const [userMessage, setUserMessage] = useState<Message | undefined>(undefined)
  const { assistantConfig } = useChats()
  const { user } = useAuth()

  console.log("useSendMessage hook")
  const [assistantMessage, setAssistantMessage] = useState<
    LoadingData<AssistantMessage>
  >({
    loading: true,
    data: undefined,
    error: undefined,
  })

  function submitMessage(message: string, chatIdAux: string | undefined) {
    if (!chatIdAux) {
      notify("Unexpected error")
      return
    }

    setUserMessage(new Message(Uuid.random().value, message, "user"))
    setAssistantMessage({
      loading: true,
      data: undefined,
      error: undefined,
    })
    user?.getIdToken().then((token) => {
      provider
        .sendMessage(
          chatIdAux,
          message,
          assistantConfig.id,
          assistantConfig.apiKey,
          token,
        )
        .then((message: AssistantMessage) => {
          console.log("useSendMessage assistant message", message)
          setAssistantMessage({
            loading: false,
            data: message,
            error: undefined,
          })
        })
        .catch((e: Error) => {
          console.log(e)
          setAssistantMessage({
            loading: false,
            data: undefined,
            error: undefined,
          })
          notify("Unexpected error")
        })
    })
  }

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object({
      message: Yup.string().required(),
    }),
    onSubmit: (values) => {
      submitMessage(values.message, chatId)
      values.message = ""
    },
  })

  function sendMessage(message: string, chatIdAux: string | undefined) {
    submitMessage(message, chatIdAux ? chatIdAux : chatId)
  }

  return {
    formik,
    userMessage,
    assistantMessageResponse: assistantMessage,
    sendMessage,
  }
}
