import { CarDealType } from "./CarDealType"
import { CarDealPurchaseType } from "./CarDealPurchaseType"
import { Utils } from "../../shared/Utils"
import { VinStructured } from "../../vin/domain/VinResponse"

export default class ChatCarDealIteration {
  constructor(
    public id: string,
    public image: string,
    public rawImageLLMResponse: string,
    public carDealDetailsJsonNoNormalized: object,
    public carDealDetailsJsonNormalized: object, //TODO: Potentially this should be a CarDealDetails object so we can use it in the domain layer filling it either with the image or from a form
    public vin?: string,
    public vinStructured?: VinStructured,

    public carDealType?: CarDealType,
    public carDealPurchaseType?: CarDealPurchaseType,
    public isTradeIn?: boolean,
    public outTheDoorPrice?: number,
    public createdAt: Date = new Date(),
  ) {}

  toPrimitives(): any {
    return {
      id: this.id,
      image: this.image,
      rawImageLLMResponse: this.rawImageLLMResponse,
      carDealDetailsJsonNoNormalized: this.carDealDetailsJsonNoNormalized,
      carDealDetailsJsonNormalized: this.carDealDetailsJsonNormalized,
      vin: this.vin,
      vinStructured: this.vinStructured ? this.vinStructured : undefined,
      carDealType: this.carDealType,
      carDealPurchaseType: this.carDealPurchaseType,
      isTradeIn: this.isTradeIn,
      outTheDoorPrice: this.outTheDoorPrice,
      createdAt: this.createdAt,
    }
  }

  static fromPrimitives(primitives: any): ChatCarDealIteration {
    return new ChatCarDealIteration(
      Utils.normalizeId(primitives),
      primitives.image,
      primitives.rawImageLLMResponse,
      primitives.carDealDetailsJsonNoNormalized,
      primitives.carDealDetailsJsonNormalized,
      primitives.vin,
      primitives.vinStructured ? primitives.vinStructured : undefined,
      primitives.carDealType,
      primitives.carDealPurchaseType,
      primitives.isTradeIn,
      primitives.outTheDoorPrice,
      new Date(primitives.createdAt),
    )
  }
}
