import { FC } from "react"

type Props = {
  className?: string
  text: string
  onClick: () => void
}
const SidePanelItem: FC<Props> = ({ className, text, onClick }) => {
  return (
    <div className="w-52 cursor-pointer" onClick={onClick}>
      <p className="p-4 text-sm">{text}</p>
    </div>
  )
}

export default SidePanelItem
