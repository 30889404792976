import { FC } from "react"
import SidePanelHeaderItem from "./SidePanelHeaderItem"
import SidePanelItem from "./SidePanelItem"
import ChatCarDeal from "../../models/ChatCarDeal"
import SidePanelItemToggle from "./SidePanelItemToggle"

type Props = {
  text: string
  carDeal?: ChatCarDeal
  className?: string
  onClickAssistantConfig: () => void
  onClickCarDeal?: () => void
  onMessageDebug?: (debug: boolean) => void
}
const ChatSidePanel: FC<Props> = ({
  text,
  carDeal,
  onClickAssistantConfig,
  onClickCarDeal,
  onMessageDebug,
}) => {
  return (
    <div className="bg-light-details rounded-md">
      <SidePanelHeaderItem text={text} />
      <SidePanelItem
        text={"Assistant config"}
        onClick={onClickAssistantConfig}
      />
      {carDeal && onClickCarDeal && (
        <SidePanelItem text={"Car deal"} onClick={onClickCarDeal} />
      )}
      {onMessageDebug && (
        <SidePanelItemToggle
          text={"Messages debug"}
          onToggled={onMessageDebug}
        />
      )}
    </div>
  )
}

export default ChatSidePanel
