import { useFormik } from "formik"
import * as Yup from "yup"
import { notify } from "../../shared/components/toasts/ToastUtils"
import InvalidCredentialsError from "../../shared/exceptions/InvalidCredentialsError"
import React from "react"
import { useNavigate } from "react-router-dom"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import { useAuth } from "../../auth/context/AuthContext"

export const useLogin = () => {
  const { setCurrentUser } = useAuth()
  const history = useNavigate()
  const [loading, setLoading] = React.useState(false)

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("* Required"),
      password: Yup.string().required("* Required"),
    }),
    onSubmit: (values) => {
      setLoading(true)
      const auth = getAuth()
      signInWithEmailAndPassword(
        auth,
        values.email.trim().toLowerCase(),
        values.password,
      )
        .then((userCredential) => {
          console.log("login onSubmit")
          console.log(userCredential)
          setCurrentUser(userCredential.user)
          history(`/`, { replace: true })
        })
        .catch((error) => {
          setLoading(false)
          console.log("login onSubmit error")
          console.log(error)
          if (error instanceof InvalidCredentialsError) {
            notify("Invalid credentials")
            return
          }
          notify("Unexpected error")
        })
    },
  })

  return {
    formik,
    loading,
  }
}
