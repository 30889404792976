import logo from "./logo.svg"
import "./App.css"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className="font-bold underline text-3xl ">Hello world!</h1>

        <h2 className="text-2xl text-red-800">ASDADS</h2>
        <button className="btn ">
          Button
          <span className="loading loading-spinner"></span>
        </button>

        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  )
}

export default App
