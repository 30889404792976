import { Message } from "./Message"
import { AssistantMessage } from "./AssistantMessage"

export default class Interaction {
  constructor(
    readonly interactionId: string,
    readonly question: Message,
    public response: AssistantMessage | undefined,
    readonly cost: number,
    readonly createdAt: Date = new Date(),
  ) {}

  toPrimitives() {
    return {
      interactionId: this.interactionId,
      question: this.question.toPrimitives(),
      response: this.response?.toPrimitives(),
      cost: this.cost,
      createdAt: this.createdAt,
    }
  }
}
