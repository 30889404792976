import Interaction from "./Interaction"
import { AssistantMessage } from "./AssistantMessage"
import { Message } from "./Message"
import ChatCarDeal from "./ChatCarDeal"
import { Utils } from "../../shared/Utils"
import UserChat from "./UserChat"

export default class Chat {
  constructor(
    public id: string,
    public user: UserChat,
    public interactions: Interaction[] = [],
    public active: boolean = true,
    public totalCost: number,
    public createdAt: Date,
    public threadId?: string,
    public carDeal?: ChatCarDeal,
    public assistantId?: string,
    public assistantApiKey?: string,
  ) {}

  addInteraction(interaction: Interaction): void {
    this.interactions.push(interaction)
    this.totalCost += interaction.cost ?? 0
  }

  toPrimitives(): any {
    return {
      _id: this.id,
      user: this.user,
      interactions: this.interactions
        ? this.interactions.map((interaction) => interaction.toPrimitives())
        : [],
      active: this.active,
      totalCost: this.totalCost,
      createdAt: this.createdAt,
      threadId: this.threadId,
      carDeal: this.carDeal ? this.carDeal.toPrimitives() : undefined,
      assistantId: this.assistantId,
      assistantApiKey: this.assistantApiKey,
    }
  }

  get messages(): Message[] {
    const messages: Message[] = []
    for (const interaction of this.interactions) {
      messages.push(interaction.question)
      if (interaction.response) messages.push(interaction.response)
    }
    return messages
  }

  static fromPrimitives(primitives: any): Chat {
    return new Chat(
      Utils.normalizeId(primitives),
      UserChat.fromPrimitives(primitives.user),
      primitives.interactions
        ? primitives.interactions.map((interaction: any) => {
            let interactionId = ""
            if (typeof interaction.interactionId == "string") {
              interactionId = interaction.interactionId
            } else {
              interactionId = interaction.interactionId.value
            }
            return new Interaction(
              interactionId,
              Message.fromPrimitives(interaction.question),
              interaction.response
                ? AssistantMessage.fromPrimitives(interaction.response)
                : undefined,
              interaction.cost ?? 0,
              new Date(interaction.createdAt),
            )
          })
        : [],
      primitives.active,
      primitives.totalCost,
      new Date(primitives.createdAt),
      primitives.threadId,
      primitives.carDeal
        ? ChatCarDeal.fromPrimitives(primitives.carDeal)
        : undefined,
      primitives.assistantId,
      primitives.assistantApiKey,
    )
  }
}
