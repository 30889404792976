import { FC } from "react"
import { CopyIcon } from "../../../assets/CopyIcon"
import { notifySuccess } from "../toasts/ToastUtils"
import { Utils } from "../../Utils"

type Props = {
  uuid: string
  className?: string
  slice?: boolean
}
export const UUid: FC<Props> = ({ uuid, className, slice = true }) => {
  return (
    <div className={` flex`}>
      <span className={`${className}`}>{slice ? uuid.slice(-12) : uuid}</span>
      <CopyIcon
        onClick={() => {
          Utils.copyToClipboard(uuid).then(() => {
            notifySuccess(uuid + " copied to clipboard")
          })
          /*navigator.clipboard.writeText(uuid).then(() => {
            notifySuccess(uuid + " copied to clipboard")
          })*/
        }}
        className="h-3 ml-4 fill-current cursor-pointer self-center"
      />
    </div>
  )
}
