import { FC } from "react"
import { Message } from "../models/Message"
import Markdown from "react-markdown"

const ChatUserMessage: FC = () => {
  return (
    <div key={"system"}>
      <span className="my-8 loading loading-dots loading-md"></span>
      <div className="h-0.5 bg-divider my-6 rounded"></div>
    </div>
  )
}

export default ChatUserMessage
