import { FC } from "react"

type Props = {
  title: string
  subtitle: string
  onClick: () => void
  className?: string
}
const SuggestionBoxNoMessageChatView: FC<Props> = ({
  title,
  subtitle,
  onClick,
  className,
}) => {
  return (
    <div
      onClick={onClick}
      className="flex-1 border-[1px] border-light-details cursor-pointer rounded-lg mr-2 p-3 mb-1 mt-1 hover:bg-light-details"
    >
      <p className="text-sm font-semibold mb-1">{title}</p>
      <p className="text-xs">{subtitle}</p>
    </div>
  )
}

export default SuggestionBoxNoMessageChatView
