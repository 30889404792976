// src/AuthContext.js

import React, {
  createContext,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"

import firebaseApp from "../../firebaseConfig"
import { User } from "@firebase/auth"
import { useAuthState, useSignOut } from "react-firebase-hooks/auth"
import UserApiProvider from "../../providers/user/UserApiProvider"
import { BASE_URL } from "../../shared/constants/constants"
import UserCustom from "../../user/model/User"

type Context = ReturnType<typeof useAuthContext>
const AuthContext = createContext<Context | undefined>(undefined)
export const AuthProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  return (
    <AuthContext.Provider value={useAuthContext()}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) throw Error("AuthContext not available")
  return context
}

function useAuthContext(): {
  user: User | null | undefined
  userBackend: UserCustom | null
  loading: boolean
  setCurrentUser: React.Dispatch<SetStateAction<User | null>>
} {
  const [userBackend, setUserBackend] = useState<UserCustom | null>(null)
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [user, loading] = useAuthState(getAuth(firebaseApp))

  useEffect(() => {
    try {
      user?.getIdToken().then(async (token) => {
        try {
          const provider = new UserApiProvider(BASE_URL)
          const userFromResponse = await provider.me(token)
          setUserBackend(userFromResponse)
        } catch (e) {
          console.log("Error fetching user from backend")
        }
      })
    } catch (e) {
      console.log("Error getting token")
    }
  }, [user])

  return { user: user, userBackend, loading, setCurrentUser }
}
