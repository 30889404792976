import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import CustomError from "../exceptions/CustomError"

export const useCustomNavigation = () => {
  const history = useNavigate()
  const [errorCode, setErrorCode] = useState<string | undefined>()

  const actionByError = (errorCode: string, history) => {
    console.log("actionByError", errorCode)
    if (CustomError.TOKEN_INVALID === errorCode) {
      console.log("CustomError.TOKEN_INVALID")
      history(`/login`, {
        replace: true,
      })
    }
  }

  useEffect(() => {
    if (errorCode) {
      actionByError(errorCode, history)
    }
  }, [errorCode, history])

  return { setErrorCode }
}
