import { FC, useEffect, useRef } from "react"
import * as React from "react"
import { useSendMessage } from "../hooks/useSendMessage"
import ChatApiProvider from "../../providers/chat/ChatApiProvider"
import { BASE_URL } from "../../shared/constants/constants"
import { Message } from "../models/Message"
import { AssistantMessage } from "../models/AssistantMessage"
import { BoltIcon } from "@heroicons/react/24/outline"
import { PaperAirplaneIcon, ListBulletIcon } from "@heroicons/react/24/outline"
import CarDealUploadImageDialog from "./CarDealUploadImageDialog"
import Chat from "../models/Chat"
import { UserRole } from "../../user/model/UserRole"
import { useAuth } from "../../auth/context/AuthContext"
import classNames from "classnames"

type Props = {
  chatId: string
  className?: string
  onSendMessage: (userMessage: Message) => void
  onAssistantMessage: (userMessage: AssistantMessage) => void
  onChange: (e: any) => void
  onChatHistory: () => void
  onChatDetails: (show: boolean) => void
  onChatDealUploaded: (chat: Chat) => void
  showChatDetails: boolean
}
const InputSendMessage: FC<Props> = ({
  chatId,
  className,
  onSendMessage,
  onAssistantMessage,
  onChatHistory,
  onChatDetails,
  onChatDealUploaded,
  showChatDetails,
}) => {
  const { userBackend } = useAuth()
  const { formik, userMessage, assistantMessageResponse } = useSendMessage(
    chatId,
    new ChatApiProvider(BASE_URL),
  )
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (userMessage) {
      onSendMessage(userMessage!)
    }
  }, [userMessage])

  useEffect(() => {
    if (assistantMessageResponse.data) {
      onAssistantMessage(assistantMessageResponse.data!)
    }
  }, [assistantMessageResponse])
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef!.current!.focus()
    }
  }, [])
  const chatDetailsText = showChatDetails
    ? "Hide chat details"
    : "Show chat details"

  const formClass = classNames({
    "flex rounded-lg items-center justify-center mr-4 ml-4 md:ml-4 mb-4 mx-auto":
      true,
    "md:mr-60": userBackend?.role === UserRole.ADMIN,
    "md:mr-12": userBackend?.role !== UserRole.ADMIN,
  })

  return (
    <div
      className={`flex-1 bg-gradient-to-t from-white via-white to-transparent ${className}`}
    >
      <form onSubmit={formik.handleSubmit} className={`${formClass}`}>
        <textarea
          ref={textAreaRef}
          placeholder="Type here"
          className="textarea textarea-info w-full max-w-4xl mr-4"
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.altKey) {
              formik.handleSubmit()
            }
          }}
          {...formik.getFieldProps("message")}
        />
        <ListBulletIcon
          className="w-10 h-10 mr-2 cursor-pointer text-secondary block md:hidden"
          onClick={onChatHistory}
        />
        <div className="flex">
          <div className="dropdown dropdown-top dropdown-end">
            <div tabIndex={0} role="button" className="">
              <BoltIcon className="cursor-pointer text-secondary h-6  mr-2" />
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content mb-4 z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <a
                  onClick={() => {
                    ;(
                      document.getElementById(
                        "car_deal_upload_image",
                      ) as HTMLFormElement
                    ).showModal()
                  }}
                >
                  Upload car deal
                </a>
              </li>

              {userBackend?.role === UserRole.ADMIN && (
                <li>
                  <a
                    className="block md:hidden"
                    onClick={() => {
                      onChatDetails(!showChatDetails)
                    }}
                  >
                    {chatDetailsText}
                  </a>
                </li>
              )}
            </ul>
          </div>
          <PaperAirplaneIcon
            className="w-6 h-6 cursor-pointer text-secondary"
            onClick={() => {
              formik.handleSubmit()
            }}
          />
        </div>
      </form>

      <CarDealUploadImageDialog
        chatId={chatId}
        onUploaded={(chat: Chat) => {
          onChatDealUploaded(chat)
          ;(
            document.getElementById("car_deal_upload_image") as HTMLFormElement
          ).close()
        }}
      />
    </div>
  )
}

export default InputSendMessage
