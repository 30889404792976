import { FC, useEffect, useState } from "react"
import ChatApiProvider from "../../providers/chat/ChatApiProvider"
import { BASE_URL } from "../../shared/constants/constants"
import Chat from "../models/Chat"
import { AssistantMessage } from "../models/AssistantMessage"
import { useChats } from "../hooks/ChatsContext"
import { LinesShimmer } from "../../shared/components/shimmers/LinesShimmer"

type Props = {
  className: string
  onChatClick: (chat: Chat) => void
}
const ChatList: FC<Props> = ({ className, onChatClick }) => {
  /*const { chatResponse, setChats } = useChats(new ChatApiProvider(BASE_URL))*/
  const { chatResponse } = useChats()
  const [selectedId, setSelectedId] = useState<string>("")

  const simmerReps = Array.from({ length: 4 })
  return (
    <div className={` ${className}`}>
      {chatResponse.loading &&
        simmerReps.map((_, index) => (
          <div
            key={index}
            className={`mx-4 my-4 items-center justify-center rounded-lg border-[1px] border-light-details px-3 py-5`}
          >
            <div className="flex items-center mb-4">
              <LinesShimmer thinLine={true} />
              <LinesShimmer thinLine={true} mRightPx={0} className={"pl-6"} />
            </div>
            <LinesShimmer nLines={2} thinLine={true} />
          </div>
        ))}

      {chatResponse.data?.map((chat: Chat, index) => {
        const classContainer =
          selectedId === chat.id ? "bg-lighter-details" : ""
        const classText = selectedId === chat.id ? " font-bold" : ""
        return (
          <div
            key={chat.id}
            className={`mx-4 p-3 my-4 border-[1px] border-light-details hover:bg-lighter-details cursor-pointer rounded-lg justify-center items-center ${classContainer}`}
            onClick={() => {
              onChatClick(chat)
              setSelectedId(chat.id)
            }}
          >
            <div className="flex items-center mb-2">
              <span className="text-sm font-semibold">{chat.user.name}</span>
              <span className="ml-auto text-xs font-semibold">
                {chat.createdAt.toLocaleDateString()}
              </span>
            </div>
            <span
              className={`line-clamp-2 text-sm ${classText}`}
              key={chat.messages.length > 0 ? chat?.messages[0].id : index}
            >
              {chat.messages.length > 0 ? chat.messages[0].text : ""}
            </span>
          </div>
        )
      })}
    </div>
  )
}
export default ChatList
