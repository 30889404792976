import { useFormik } from "formik"
import * as Yup from "yup"
import { JwtInfo } from "../../shared/models/user/JwtInfo"
import { notify } from "../../shared/components/toasts/ToastUtils"
import InvalidCredentialsError from "../../shared/exceptions/InvalidCredentialsError"
import React, { useEffect } from "react"
import { BASE_URL } from "../../shared/constants/constants"
import CarDealsProvider from "../../providers/carDeals/CarDealsProvider"
import { UUid } from "../../shared/components/Uuid/Uuid"
import { Uuid } from "../../shared/models/UuidModel"
import ChatProvider from "../../providers/chat/ChatProvider"
import { useChats } from "./ChatsContext"
import AssistantConfig from "../models/AssistantConfig"

export const useAssistantConfig = () => {
  const { assistantConfig, setAssistantConfig } = useChats()
  const [success, setSuccess] = React.useState(false)

  const formik = useFormik({
    initialValues: {
      assistant_id: assistantConfig.id,
      assistant_api_key: assistantConfig.apiKey,
    },
    validationSchema: Yup.object({
      assistant_id: Yup.string().required("* Required"),
      assistant_api_key: Yup.string().required("* Required"),
    }),
    onSubmit: async (values) => {
      try {
        setAssistantConfig(
          new AssistantConfig(values.assistant_id, values.assistant_api_key),
        )
        setSuccess(true)
      } catch (e) {
        setSuccess(false)
        console.log("useCreateNewDeal error")
        notify("Unexpected error")
      }
    },
  })

  return {
    formik,
    success,
  }
}
