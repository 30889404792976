import React, { FC, useEffect, useRef } from "react"
import Chat from "../models/Chat"
import { Message } from "../models/Message"
import ChatUserMessage from "./ChatUserMessage"
import ChatSystemMessage from "./ChatSystemMessage"
import ChatSystemLoader from "./ChatSystemLoader"
import ChatSidePanel from "./SidePanel/ChatSidePanel"
import UpdateAssistantConfigDialog from "./UpdateAssistantConfigDialog"
import ChatCarDealDialog from "./ChatCarDealDialog"
import { useChats } from "../hooks/ChatsContext"
import AssistantConfig from "../models/AssistantConfig"
import {
  API_KEY_ASSISTANT_DEFAULT,
  ID_ASSISTANT_DEFAULT,
} from "../constants/AssistantConstants"
import classNames from "classnames"
import { useSendMessage } from "../hooks/useSendMessage"
import ChatApiProvider from "../../providers/chat/ChatApiProvider"
import { BASE_URL } from "../../shared/constants/constants"
import { AssistantMessage } from "../models/AssistantMessage"
import ChatSystemMessageBeautify from "./ChatSystemMessageBeautify"
import { useAuth } from "../../auth/context/AuthContext"
import { UserRole } from "../../user/model/UserRole"
import { MessageType } from "../models/MessageType"
import ChatUserCarDealMessage from "./ChatUserCarDealMessage"

type Props = {
  chat: { chat: Chat; loading: boolean }
  className?: string
  showChatDetails: boolean
  onSendMessage: (message: Message, chatId: string) => void
  onAssistantMessage: (userMessage: AssistantMessage) => void
}

const ChatMessages: FC<Props> = ({
  chat,
  className,
  showChatDetails,
  onSendMessage,
  onAssistantMessage,
}) => {
  const { userBackend } = useAuth()
  const [messagesDebug, setMessagesDebug] = React.useState<boolean>(false)
  const { setAssistantConfig } = useChats()
  const endOfMessagesRef = useRef<null | HTMLDivElement>(null)

  const { sendMessage, userMessage, assistantMessageResponse } = useSendMessage(
    chat.chat.id,
    new ChatApiProvider(BASE_URL),
  )
  useEffect(() => {
    if (userMessage) {
      onSendMessage(userMessage!, chat.chat.id)
    }
  }, [userMessage])

  useEffect(() => {
    if (assistantMessageResponse.data) {
      onAssistantMessage(assistantMessageResponse.data!)
    }
  }, [assistantMessageResponse])

  useEffect(() => {
    if (
      endOfMessagesRef.current &&
      typeof endOfMessagesRef.current?.scrollIntoView === "function"
    ) {
      endOfMessagesRef.current!.scrollIntoView({ behavior: "smooth" })
    }
  }, [chat?.chat?.messages])

  useEffect(() => {
    setAssistantConfig(
      new AssistantConfig(
        chat.chat.assistantId ?? ID_ASSISTANT_DEFAULT,
        chat.chat.assistantApiKey ?? API_KEY_ASSISTANT_DEFAULT,
      ),
    )
  }, [chat?.chat?.assistantId, chat.chat.assistantApiKey])

  const chatDetailsPanelClass = classNames({
    "mt-3 mr-4 ml-4 md:ml-0 md:block md:w-52": true,
    "w-full": showChatDetails,
    "w-0 hidden": !showChatDetails,
  })

  function getSysteMessage(
    message: Message,
    index: number,
  ): React.ReactNode | undefined {
    switch (message.getType()) {
      case MessageType.TEXT:
        return getSystemTextMessage(message, index)
    }
    return undefined
  }

  function getSystemTextMessage(
    message: Message,
    index: number,
  ): React.ReactNode | undefined {
    if (messagesDebug) {
      return (
        <ChatSystemMessage
          message={message}
          onSelectSendMessageButton={(text) => {
            sendMessage(text, undefined)
          }}
        />
      )
    }
    return (
      <ChatSystemMessageBeautify
        message={message}
        onSelectSendMessageButton={(text) => {
          sendMessage(text, undefined)
        }}
        renderButtonActions={chat?.chat?.messages.length - 1 === index}
        onScrollBottom={() => {
          if (
            endOfMessagesRef.current &&
            typeof endOfMessagesRef.current?.scrollIntoView === "function"
          ) {
            endOfMessagesRef.current!.scrollIntoView({
              behavior: "smooth",
            })
          }
        }}
      />
    )
  }

  function getUserMessage(message: Message): React.ReactNode | undefined {
    switch (message.getType()) {
      case MessageType.TEXT:
        return <ChatUserMessage message={message} />
      case MessageType.CAR_DEAL:
        return <ChatUserCarDealMessage message={message} />
    }
    return undefined
  }

  return (
    <div className="flex">
      <div className={`${className} overflow-y-scroll`}>
        {chat?.chat?.messages?.map((message: Message, index) => {
          return (
            <div className="mx-4 md:mx-8" key={message.id + "PARENT"}>
              {message.role === "user" && getUserMessage(message)}
              {message.role !== "user" && getSysteMessage(message, index)}
              {chat?.loading && chat?.chat?.messages.length - 1 === index && (
                <ChatSystemLoader />
              )}
            </div>
          )
        })}
        <div ref={endOfMessagesRef} />
      </div>
      {/*<div className="hidden w-0 mt-3 mr-4 sm:block sm:w-52">*/}
      {userBackend?.role === UserRole.ADMIN && (
        <div className={chatDetailsPanelClass}>
          <ChatSidePanel
            text={"Chat details"}
            carDeal={chat?.chat?.carDeal}
            onClickCarDeal={() => {
              ;(
                document.getElementById(
                  "chat_car_deal_details",
                ) as HTMLFormElement
              ).showModal()
            }}
            onClickAssistantConfig={() => {
              ;(
                document.getElementById("assistant_config") as HTMLFormElement
              ).showModal()
            }}
            onMessageDebug={(debug: boolean) => {
              setMessagesDebug(debug)
            }}
          />
        </div>
      )}
      <UpdateAssistantConfigDialog />
      {chat?.chat?.carDeal && <ChatCarDealDialog carDeal={chat.chat.carDeal} />}
    </div>
  )
}

export default ChatMessages
