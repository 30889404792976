import { Utils } from "../../shared/Utils"

export default class UserCustom {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly email: string,
    public readonly role: string,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {}

  toPrimitives(): any {
    return {
      _id: this.id,
      name: this.name,
      email: this.email,
      role: this.role,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }

  static fromPrimitives(primitives: any): UserCustom {
    return new UserCustom(
      Utils.normalizeId(primitives),
      primitives.name,
      primitives.email,
      primitives.role,
      new Date(primitives.createdAt),
      new Date(primitives.updatedAt),
    )
  }
}
