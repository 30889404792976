import { Utils } from "../../shared/Utils"
import MessageMetadata from "./MessageMetadata"
import { MessageType } from "./MessageType"

export class Message {
  constructor(
    readonly id: string,
    readonly text: string,
    readonly role:
      | "system"
      | "user"
      | "assistant"
      | "tool"
      | "function" = "user",
    readonly visible: boolean = true,
    readonly createdAt: Date = new Date(),
    readonly metadata?: MessageMetadata,
  ) {}

  getType(): MessageType {
    if (this.metadata?.carDeal) {
      return MessageType.CAR_DEAL
    }
    return MessageType.TEXT
  }

  public toPrimitives() {
    return {
      id: this.id,
      text: this.text,
      role: this.role,
      visible: this.visible,
      createdAt: this.createdAt,
      metadata: this.metadata ? this.metadata.toPrimitives() : undefined,
    }
  }

  static fromPrimitives(primitives: any): Message {
    console.log("-------primitives.metadata--------", primitives.metadata)
    return new Message(
      Utils.normalizeId(primitives),
      primitives.text,
      primitives.role,
      primitives.visible,
      new Date(primitives.createdAt),
      primitives.metadata
        ? MessageMetadata.fromPrimitives(primitives.metadata)
        : undefined,
    )
  }
}
